import axios from "axios"

const API = "configurar/productos";

const datosTienda = {
    getDatosUnidades(id_tienda,tipo_tienda){
        return axios(`${API}/${id_tienda}/${tipo_tienda}/get-unidades`)
    },
    getDatosCategorias(id_tienda,tipo_tienda){
        return axios(`${API}/${id_tienda}/${tipo_tienda}/get-categorias`)
    },
    getDatosEtiquetas(id_tienda,tipo_tienda){
        return axios(`${API}/${id_tienda}/${tipo_tienda}/get-etiquetas`)
    },
    insertUnidad(params){
        return axios.post(`${API}/insert-unidad`, params)
    },
    updateUnidad(params){
        return axios.post(`${API}/update-unidad`, params)
    },
    deleteUnidad(id){
        return axios.delete(`${API}/${id}/delete-unidad`);
    },
    insertCategoria(params){
        return axios.post(`${API}/insert-categoria`, params)
    },
    updateCategoria(params){
        return axios.post(`${API}/update-categoria`, params)
    },
    deleteCategoria(id,id_nueva){
        return axios.delete(`${API}/${id}/${id_nueva}/delete-categoria`);
    },
    insertEtiqueta(params){
        return axios.post(`${API}/insert-etiqueta`, params)
    },
    updateEtiqueta(params){
        return axios.post(`${API}/update-etiqueta`, params)
    },
    deleteEtiqueta(id){
        return axios.delete(`${API}/${id}/delete-etiqueta`);
    },
    getSubCategorias(idCategoria){
        return axios.get(`${API}/categoria/${idCategoria}/subcategorias/listar`);
    },
    postSubCategoria(payload){
        return axios.post(`${API}/categoria/subcategorias/guardar`, payload)
    },
    putSubCategoria(idSubCategoria, payload){
        return axios.put(`${API}/categoria/subcategorias/${idSubCategoria}/actualizar`, payload)
    },
    deleteSubCategoria(idSubCategoria){
        return axios.delete(`${API}/categoria/subcategorias/${idSubCategoria}/eliminar`)
    },
    orderCategories(payload){
        return axios.post(`${API}/categorias/ordenar`, payload)
    }
}

export default datosTienda
